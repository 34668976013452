

// http://192.168.1.40/cgi-bin/mjpeg?resolution=640x360&framerate=30&quality=1

import { Framerate, Resolution, Scheme } from "./cameraParams"

interface Params {
    scheme?: Scheme;
    address: string;
    resolution: Resolution;
    framerate: Framerate;
}

export const Preview: React.FC<Params> = (params) => {
    console.log("Opening Preview");

    const sourceUrl = (): string => {
        const url = new URL("/cgi-bin/mjpeg", Scheme[params.scheme || "HTTP"] + params.address);
        const searchParams: Record<string, string> = {
            resolution: Resolution[params.resolution].join("x"),
            framerate: params.framerate.toString(),
            quality: "1"
        }
        url.search = new URLSearchParams(searchParams).toString();

        return url.toString();
    };

    return (
        <img 
        className="preview" 
        src={sourceUrl()} 
        alt="preview"
        />
    )
}

Preview.defaultProps = {
    scheme: "HTTP"
}
