/* eslint-disable @typescript-eslint/no-redeclare */
export const Scheme = {
    "HTTP": "http://",
    "HTTPS": "https://",
}
export type Scheme = keyof typeof Scheme;

export const Resolution = {
    "640x360": [640, 360],
    "854x480": [854, 480],
    "960x540": [960, 540],
    "1280x720": [1280, 720],
    "1600x900": [1600, 900],
    "1920x1080": [1920, 1080],
} as const;
export type Resolution = keyof typeof Resolution;

export const Framerate = {
    "5fps": 5,
    "10fps": 10,
    "15fps": 15,
    "20fps": 20,
    "25fps": 25,
    "30fps": 30,
} as const;
export type Framerate = keyof typeof Framerate;
