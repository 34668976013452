import { Config } from "./App";
import { Framerate, Resolution, Scheme } from "./cameraParams"

interface AddressProps {
    value: string;
    onChange: (event: string) => void
}

const AddressEntry: React.FC<AddressProps> = (props) => {
    return (
        <input type="text" name="address" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    )
}

interface SelectProps<T> {
    value: T;
    onChange: (data: T) => void
}

const SchemeSelect: React.FC<SelectProps<Scheme>> = (props) => {
    const options = () => 
        Object.keys(Scheme).map(scheme => <option key={scheme} value={scheme}>{scheme}</option>);
    return <select value={props.value} onChange={(e) => props.onChange(e.target.value as Scheme)}>{options()}</select>;
}

const ResolutionSelect: React.FC<SelectProps<Resolution>> = (props) => {
    const options = () => 
        Object.keys(Resolution).map(resolution => <option key={resolution} value={resolution}>{resolution}</option>);
    return <select value={props.value} onChange={(e) => props.onChange(e.target.value as Resolution)}>{options()}</select>;
}

const FramerateSelect: React.FC<SelectProps<Framerate>> = (props) => {
    const options = () => 
        Object.keys(Framerate).map(framerate => <option key={framerate} value={framerate}>{framerate}</option>);
    return <select value={props.value} onChange={(e) => props.onChange(e.target.value as Framerate)}>{options()}</select>;
}

interface ButtonProps {
    onPressed: () => void;
}

const ReconnectButton: React.FC<ButtonProps> = (props) => {
    return (
        <button onClick={props.onPressed}>Connect</button>
    )
}

interface ToolbarProps {
    config: Config
    onChange: (config: Config) => void;
}

export const Toolbar: React.FC<ToolbarProps> = (props) => {
    const handleScheme = (scheme: Scheme) => props.onChange({...props.config, scheme});
    const handleAddress = (address: string) => props.onChange({...props.config, address});
    const handleResolution = (resolution: Resolution) => props.onChange({...props.config, resolution});
    const handleFramerate = (framerate: Framerate) => props.onChange({...props.config, framerate});

    const handleConnect = () => props.onChange(props.config);

    return (
        <div className="toolbar">
            <SchemeSelect value={props.config.scheme} onChange={handleScheme}/>
            <AddressEntry value={props.config.address} onChange={handleAddress}/>
            <ResolutionSelect value={props.config.resolution} onChange={handleResolution}/>
            <FramerateSelect value={props.config.framerate} onChange={handleFramerate}/>

            <ReconnectButton onPressed={handleConnect}/>
        </div>
    )
}
