import React, { useState } from 'react';
import './App.css';
import { Framerate, Resolution, Scheme } from './cameraParams';
import { Preview } from './Preview';
import { Toolbar } from './Toolbar';

export interface Config {
  scheme: Scheme;
  address: string;
  resolution: Resolution;
  framerate: Framerate;
}

function useStickyState<T>(defaultValue: T, key: string) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

function App() {
  const [config, setConfig] = useStickyState<Config>({
    scheme: "HTTP",
    address: "192.168.1.40",
    resolution: "640x360",
    framerate: "30fps"
  }, "cam-config");
  const [redo, setRedo] = useState<boolean>(false);

  const handleChange = (cfg: Config) => {
    setConfig(cfg);
    setRedo(!redo);
  }

  return (
    <div id="app">
      <Preview
        scheme={config.scheme}
        address={config.address}
        framerate={config.framerate}
        resolution={config.resolution}
      />

      <div id="tools">
        <Toolbar config={config} onChange={handleChange}/>
      </div>
    </div>
  );
}

export default App;
